import React from 'react';
import './App.css';

function App() {
  return (
    <>
      <h2>Welcome to True Resume</h2>
    </>
  );
}

export default App;
